import "../styles/index.scss";

import $ from "jquery";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import "../../static/vendor/js/form-validation.min";

document.addEventListener("DOMContentLoaded", () => {
  appearOnSlider();
  locationSlider();
  enableNav();
  animation();
  contactUsSubmit();
  navBarActive();
  formContactUsSubmission();
  contactUsSubmit();
  // $(function() {
  //   $('svg').each(function() {
  //     var svg = $(this);
  //     var text = svg.find('text');
  //     var bbox = text.get(0).getBBox();
  //     //the hack
  //     var calcString = "calc(100% * " + bbox.height/bbox.width + ")";
  //     svg.css("padding-bottom",calcString);

  //     svg.get(0).setAttribute('viewBox',
  //                            [bbox.x,
  //                             bbox.y,
  //                             bbox.width,
  //                             bbox.height].join(' '));
  //   });
  // });
});

const enableNav = () => {
  const top_nav = document.getElementById("top-nav");
  window.addEventListener("scroll", function () {
    window.pageYOffset > 50
      ? top_nav.classList.add("active")
      : top_nav.classList.remove("active");
  });
};

const navBarActive = () => {
  const about_us = document.querySelectorAll("#about_us_nav");
  const services = document.querySelectorAll("#services_nav");
  switch (window.location.pathname) {
    case "/about-us.html":
      about_us.forEach((x) => x.classList.add("active"));
      document.title = "About Us | Vista Kid";
      break;
    case "/service.html":
      services.forEach((x) => x.classList.add("active"));
      document.title = "Our Services | Vista Kid";
      break;
    default:
      break;
  }
};

const contactUsSubmit = () => {
  const FORM_TIME_START = Math.floor(new Date().getTime() / 1000);
  let formElement = document.getElementById("tfa_0");
  if (null === formElement) {
    formElement = document.getElementById("0");
  }
  let appendJsTimerElement = function () {
    let formTimeDiff =
      Math.floor(new Date().getTime() / 1000) - FORM_TIME_START;
    let cumulatedTimeElement = document.getElementById("tfa_dbCumulatedTime");
    if (null !== cumulatedTimeElement) {
      let cumulatedTime = parseInt(cumulatedTimeElement.value);
      if (null !== cumulatedTime && cumulatedTime > 0) {
        formTimeDiff += cumulatedTime;
      }
    }
    let jsTimeInput = document.createElement("input");
    jsTimeInput.setAttribute("type", "hidden");
    jsTimeInput.setAttribute("value", formTimeDiff.toString());
    jsTimeInput.setAttribute("name", "tfa_dbElapsedJsTime");
    jsTimeInput.setAttribute("id", "tfa_dbElapsedJsTime");
    jsTimeInput.setAttribute("autocomplete", "off");
    if (null !== formElement) {
      formElement.appendChild(jsTimeInput);
    }
  };
  if (null !== formElement) {
    if (formElement.addEventListener) {
      formElement.addEventListener("submit", appendJsTimerElement, false);
    } else if (formElement.attachEvent) {
      formElement.attachEvent("onsubmit", appendJsTimerElement);
    }
  }
};

const formContactUsSubmission = () => {
  const FORM_TIME_START = Math.floor(new Date().getTime() / 1000);
  let formElement = document.getElementById("tfa_0");
  if (null === formElement) {
    formElement = document.getElementById("0");
  }
  let appendJsTimerElement = function () {
    let formTimeDiff =
      Math.floor(new Date().getTime() / 1000) - FORM_TIME_START;
    let cumulatedTimeElement = document.getElementById("tfa_dbCumulatedTime");
    if (null !== cumulatedTimeElement) {
      let cumulatedTime = parseInt(cumulatedTimeElement.value);
      if (null !== cumulatedTime && cumulatedTime > 0) {
        formTimeDiff += cumulatedTime;
      }
    }
    let jsTimeInput = document.createElement("input");
    jsTimeInput.setAttribute("type", "hidden");
    jsTimeInput.setAttribute("value", formTimeDiff.toString());
    jsTimeInput.setAttribute("name", "tfa_dbElapsedJsTime");
    jsTimeInput.setAttribute("id", "tfa_dbElapsedJsTime");
    jsTimeInput.setAttribute("autocomplete", "off");
    if (null !== formElement) {
      formElement.appendChild(jsTimeInput);
    }
  };
  if (null !== formElement) {
    if (formElement.addEventListener) {
      formElement.addEventListener("submit", appendJsTimerElement, false);
    } else if (formElement.attachEvent) {
      formElement.attachEvent("onsubmit", appendJsTimerElement);
      $("#contact-us-popup").removeClass("active");
      $("#success-query-popup").addClass("active");
    }
  }
};

$(".menu-toggler").click(function () {
  if (document.getElementById("menu-toggler").classList.contains("active")) {
    document
      .querySelectorAll("#menu-toggler")
      .forEach((x) => x.classList.remove("active"));
    document.getElementById("sidebar").classList.remove("active");
    document.querySelector("body").style.overflow = "scroll";
  } else {
    document
      .querySelectorAll("#menu-toggler")
      .forEach((x) => x.classList.add("active"));
    document.getElementById("sidebar").classList.add("active");
    document.querySelector("body").style.overflow = "hidden";
  }
});

$(".tab-header-button").each(function (e) {
  $(this).click(function (e) {
    const id = e.target.getAttribute("data-tab");
    if (id === "1") {
      document.getElementById("tab-content-1").classList.add("active");
      document.getElementById("tab-content-2").classList.remove("active");
      document.getElementById("tab-body-1").classList.add("active");
      document.getElementById("tab-body-2").classList.remove("active");
    } else if (id === "2") {
      document.getElementById("tab-content-1").classList.remove("active");
      document.getElementById("tab-content-2").classList.add("active");
      document.getElementById("tab-body-1").classList.remove("active");
      document.getElementById("tab-body-2").classList.add("active");
    }
  });
});

window.onTabLocation = (id) => {};

const appearOnSlider = () => {
  const owl = $("#appear-on-slider");
  owl.owlCarousel({
    lazyLoad: true,
    pagination: true,
    dots: false,
    nav: true,
    responsive: {
      0: {
        items: 3,
        margin: 10,
        // stagePadding: 20,
      },
      678: {
        items: 4,
        margin: 20,
        // stagePadding: 20,
      },
      1000: {
        items: 6,
        margin: 20,
        // stagePadding: 50,
      },
    },
    navText: [
      "<i class='icon-vista2-left-desktop d-none d-md-block'></i><i class='icon-vista2-left-mobile d-md-none'></i>",
      "<i class='icon-vista2-right-desktop d-none d-md-block'></i><i class='icon-vista2-right-mobile d-md-none'></i>",
    ],
  });
};

const locationSlider = () => {
  $(".location-slider").each(function () {
    $(this).owlCarousel({
      lazyLoad: true,
      items: 1,
      autoHeight: true,
      singleItem: true,
      pagination: true,
      dots: true,
      responsive: {},
    });
  });
};

window.openLocationPopup = () => {
  const modal = document.getElementById("location-image-popup");
  const modal2 = document.getElementById("location-image-pich-zoom");

  modal.classList.remove("open");
  modal2.classList.remove("open");
  modal.innerHTML = "";
  modal2.innerHTML = "";
  document.body.style.overflow = "";
};

$(".zoom-in-location").each(function (e) {
  $(this).click(function (e) {
    const modal = document.getElementById("location-image-popup");
    const modal2 = document.getElementById("location-image-pich-zoom");
    const url_image = e.target.getAttribute("data-image");

    if (modal.classList.contains("open")) {
      modal.classList.remove("open");
      modal2.classList.remove("open");
      modal.innerHTML = "";
      modal2.innerHTML = "";
      document.body.style.overflow = "";
    } else {
      modal.classList.add("open");
      document.body.style.overflow = "hidden";
      modal.innerHTML = `
          <div class="container h-100">
            <div class="row h-100">
            <span class="close-popup d-md-none" onclick="openLocationPopup('')"></span>
              <div class="offset-0 offset-md-0 col-12 col-md-12">
                <div class="content">
                  <img
                    src=${url_image}
                    alt=""
                    width="100%"
                  />
                  <div class="action pt-2 d-none d-md-flex">
                    <span class="close-popup mt-4" onclick="openLocationPopup('')"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>`;
    }
  });
});

window.pinchZoom = (url_image) => {
  // const modal = document.getElementById("location-image-popup");
  const modal2 = document.getElementById("location-image-pich-zoom");
  // modal.classList.remove("open");
  // modal.innerHTML = "";
  modal2.classList.add("open");
  modal2.innerHTML = `
      <div class="container h-100">
        <div class="row h-100">
          <div class="col-12 col-md-12">
            <div class="content">
              <img
                src=${url_image}
                alt=""
                width="100%"
              />
              <div class="close-popup" onclick="openLocationPopup('')"></div>
            </div>
          </div>
        </div>
      </div>`;
};

window.onContactUs = (status) => {
  if (status === "open") {
    $("#contact-us-popup").addClass("active");
    $("body").css({ "overflow-y": "hidden" });
  } else {
    $("#contact-us-popup").removeClass("active");
    $("body").css({ "overflow-y": "auto" });
  }
};

$(".contact-popup-form").each(function (e) {
  $(this).click(function (e) {
    const status = e.target.getAttribute("data-open");
    if (status === "true") {
      $("#contact-us-popup").addClass("active");
      $("body").css({ "overflow-y": "hidden" });
    } else {
      $("#contact-us-popup").removeClass("active");
      $("body").css({ "overflow-y": "auto" });
    }
  });
});

$(".location-list-button").each(function (e) {
  $(this).on("click", function (e) {
    const entry = e.target.getAttribute("data-category");
    const step_no = e.target.getAttribute("data-step");
    console.log({ target: e.target, entry: entry, step_no: step_no });
    if (entry === "main") {
      $("#point-main-1").removeClass("active");
      $("#point-main-2").removeClass("active");
      $("#point-main-3").removeClass("active");
      $("#point-main-4").removeClass("active");
      $(`#point-main-${step_no}`).addClass("active");
      $("#slider-main-1").removeClass("active");
      $("#slider-main-2").removeClass("active");
      $("#slider-main-3").removeClass("active");
      $("#slider-main-4").removeClass("active");
      $(`#slider-main-${step_no}`).addClass("active");
    } else if (entry === "parking") {
      $("#point-parking-1").removeClass("active");
      $("#point-parking-2").removeClass("active");
      $("#point-parking-3").removeClass("active");
      $("#point-parking-4").removeClass("active");
      $(`#point-parking-${step_no}`).addClass("active");
      $("#slider-parking-1").removeClass("active");
      $("#slider-parking-2").removeClass("active");
      $("#slider-parking-3").removeClass("active");
      $("#slider-parking-4").removeClass("active");
      $(`#slider-parking-${step_no}`).addClass("active");
    }
  });
});

const animation = () => {
  var controller = new ScrollMagic.Controller();

  var tl_left = new TimelineMax();
  var tl_top_left = new TimelineMax();
  var tl = new TimelineMax();
  var tl_mobile = new TimelineMax();
  var tl2 = new TimelineMax();
  var tl2_mobile = new TimelineMax();
  var tl3 = new TimelineMax();
  var tl3_mobile = new TimelineMax();
  $("#LEFT_ISLAND").each(function () {
    tl_left
      .to($(this), 1, {
        yPercent: "-=50",
      })
      .to($(this), 1, {
        yPercent: "+=0",
      });
  });

  $("#TOP_LEFT_ISLAND").each(function () {
    tl_top_left
      .to($(this), 1, {
        yPercent: "-=40",
      })
      .to($(this), 1, {
        yPercent: "+=0",
      });
  });

  $("#RIGHT_ISLAND").each(function () {
    tl.to($(this), 1, {
      yPercent: "-=10",
    }).to($(this), 1, {
      yPercent: "+=0",
    });
  });

  $("#RIGHT_ISLAND_MOBILE").each(function () {
    tl_mobile
      .to($(this), 5, {
        yPercent: "-=20",
      })
      .to($(this), 1, {
        yPercent: "+=0",
      });
  });

  $("#CENTRE_ISLAND").each(function () {
    tl2
      .to($(this), 1, {
        yPercent: "-=20",
      })
      .to($(this), 1, {
        yPercent: "+=0",
      });
  });

  $("#CENTRE_ISLAND_MOBILE").each(function () {
    tl2_mobile
      .to($(this), 3, {
        yPercent: "-=20",
      })
      .to($(this), 1, {
        yPercent: "+=0",
      });
  });

  $("#LEAF").each(function () {
    tl3
      .to($(this), 1, {
        yPercent: "+=10",
      })
      .to($(this), 1, {
        yPercent: "+=0",
      });
  });

  $("#LEAF_MOBILE").each(function () {
    tl3_mobile
      .to($(this), 5, {
        yPercent: "+=20",
      })
      .to($(this), 1, {
        yPercent: "+=0",
      });
  });

  // vytvoreni sceny
  var scene = new ScrollMagic.Scene({
    triggerElement: "#trigger1",
    triggerHook: 0.2,
    duration: 2000,
  })
    .setTween(tl_left)
    .addTo(controller);

  var scene = new ScrollMagic.Scene({
    triggerElement: "#trigger1",
    triggerHook: 0.2,
    duration: 2000,
  })
    .setTween(tl_top_left)
    .addTo(controller);

  var scene = new ScrollMagic.Scene({
    triggerElement: "#trigger1",
    triggerHook: 0.2,
    duration: 2000,
  })
    .setTween(tl)
    .addTo(controller);

  var scene = new ScrollMagic.Scene({
    triggerElement: "#trigger1",
    triggerHook: 0.4,
    duration: 2000,
  })
    .setTween(tl_mobile)
    .addTo(controller);

  var scene = new ScrollMagic.Scene({
    triggerElement: "#trigger1",
    triggerHook: 0.2,
    duration: 2000,
  })
    .setTween(tl2)
    .addTo(controller);

  var scene = new ScrollMagic.Scene({
    triggerElement: "#trigger1",
    triggerHook: 0.45,
    duration: 700,
  })
    .setTween(tl2_mobile)
    .addTo(controller);

  var scene = new ScrollMagic.Scene({
    triggerElement: "#trigger1",
    triggerHook: 0.2,
    duration: 2000,
  })
    .setTween(tl3)
    .addTo(controller);

  var scene = new ScrollMagic.Scene({
    triggerElement: "#trigger1",
    triggerHook: 0.3,
    duration: 2000,
  })
    .setTween(tl3_mobile)
    .addTo(controller);
};
